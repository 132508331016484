<template lang="pug">
div
  h2 Monks
  figure.highlighted
    .d-flex.mb-3.flex-wrap
      .d-flex.flex-column.align-items-center.justify-content-center.cursor-pointer.monk-container(v-for="monk in monks" @click="selected = monk")
        om-monks(:key="monk" :monk="monk")
        .mt-2(:style="selected === monk ? 'font-weight: bold': ''") {{ monk }}
    .d-flex.w-100.row.mx-0.mt-4
      .col-12
        h2.mb-4.ml-2 Usage
      .col-12
      .col-12
        .brand-use-code
          highlight.language-markup(:codeStr="code")
</template>

<script>
  import base from './base';

  const monks = require.context('@/components/Elements/Monks/svgs', true, /^.*\.svg$/);

  export default {
    mixins: [base],

    data() {
      return {
        selected: 'black-friday',
        monks: monks.keys().map((filename) => filename.substring(2).replace('.svg', '')),
      };
    },

    computed: {
      code() {
        return `om-monks(monk="${this.selected}")`;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .monk-container
    flex: 0 0 20%
    margin-top: 10px
    margin-bottom: 10px
    svg
      display: block
      height: 120px
      width: auto
</style>
